import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";

const styles = {
  container: {
    width: "70%",
    marginLeft: "59px",
    marginTop: "10px",
    marginBottom: "27px",
  },
  gridContainer: {
    padding: "14px 22px",
    borderRadius: "10px",
    background: "rgba(201, 200, 203, 0.47)",
    display: "flex",
    flexDirection: "column",
  },
  gridOptionsContainer: {
    padding: "14px 0px 14px 0px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  },
  description: {
    fontSize: "12px",
    lineHeight: "20px",
    display: "block",
  },
  button: {
    padding: "0px 1.6rem",
    borderRadius: "5px",
    height: "30px",
    border: "none",
    cursor: "pointer",
    marginTop: "9px",
    fontSize: "12px",
    lineHeight: "30px",
    color: "#2d7973",
  },
  myCustomButton: {
    backgroundColor: "#2d7973",
    color: "white",
    border: "none",
    padding: "7px 22px",
    marginRight: "17px",
    marginBottom: "18px",
    fontSize: "12px",
    width: "auto",
    display: "flex",
    borderRadius: "10px",
    marginLeft: "auto",
  },
  myCustomImgContainer: {
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  customMessageContainer: {
    display: "flex",
    margin: "18px 0px 10px 24px",
    width: "100%",
    height: "29px",
  },

  customMessageImgContainer: {
    width: "30px",
    height: "29.07px",
    borderRadius: "100%",
    background: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(227, 227, 227, 1)",
  },
  customMessageTextContainer: {
    width: "100%",
    height: "29px",
    marginLeft: "7px",
  },
  customMessageText: {
    fontSize: "12px",
    lineHeight: "30px",
  },
  imgCancelIcon: {
    color: "white",
    borderRadius: "50%",
    width: "34px",
    height: "34px",
    backgroundColor: grey[500],
  },
};

export default styles;

export const classes = makeStyles(() => ({
  accordion: {
    backgroundColor: grey[300],
    borderRadius: "8px",
    boxShadow: "none",
    maxHeight: "136.14px",
  },
  accordionExpanded: {
    maxHeight: "136.14px",
  },
  imageHeader: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    width: "109px",
  },
  imageFont: {
    fontWeight: "600",
    fontSize: "14px",
    color: "rgba(75, 75, 75, 1)",
    height: "19px",
  },
  imageShare: {
    width: "12px",
    height: "11.2px",
    color: "rgba(64, 63, 66, 1)",
  },
  shareImagePreview: {
    width: "13.5px",
    height: "12.6px",
    filter: "invert(1) brightness(2)",
  },
  productDetailFont: {
    fontWeight: "600",
    fontSize: "14px",
    color: "rgba(75, 75, 75, 1)",
    height: "19px",
  },
  productDetailContainer: {
    marginBottom: "0.5rem",
    borderRadius: "8px",
    padding: "10px",
  },
  dividerMargin: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  closeIconBox: {
    marginBottom: "7.65px",
    alignSelf: "center",
  },
  closeIcon: {
    width: "22.1px",
    height: "22.1px",
  },
  arrowBackBox: {
    position: "absolute",
    top: "44%",
    left: 0,
    zIndex: 1,
  },
  arrowBack: {
    height: "34px",
    width: "34px",
    color: "white",
  },
  arrowForward: {
    height: "34px",
    width: "34px",
    color: "white",
  },
  arrowForwardBox: {
    position: "absolute",
    top: "44%",
    zIndex: 1,
    right: -6,
  },
  modalBox: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
    padding: "32px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },
}));
