import React from "react";
import AttachEmailOutlinedIcon from "@mui/icons-material/AttachEmailOutlined";

import styles from "./styles";

const MyCustomChatMessage = (props) => {
  const handleImage = () => {
    props.actions.handleShowImage();
  };

  const imageArray = Object.values(props.state.imageUrls || {});

  return (
    <button style={styles.myCustomButton} onClick={handleImage} type="button">
      <div style={styles.myCustomImgContainer}>
        <AttachEmailOutlinedIcon />
        {imageArray.length > 1 && (
          <span style={{ textDecoration: "underline", marginLeft: "10px" }}>
            + {imageArray.length - 1}
          </span>
        )}
      </div>
    </button>
  );
};

export default MyCustomChatMessage;
