export const path = {
  login: "/login",
  tvScreen: "/tv-screen/:id",
  lotImageViewer: "/lotImageViewer",

  auctioneerDashboard: "/auctioneer-dashboard",
  auctioneerPlatform: "/auctioneer-platform/:id",
  auctioneerTimerPage: "/auctioneer-timer-page/:id/:lotId",

  bidderDashboard: "/bidder-dashboard",
  bidderPlatform: "/bidder-platform/:id",
  mybuys: "/bidder-mybuys/:id",
  todaysMyBuys: "/todays-my-buys",
  details: "/bidder-details/:date/:id",
  myBooks: "/bidder-transactions",
  myBuysTransactions: "/bidder-transactions/my-buys/:mandiId",
  myPaymentsTransactions: "/bidder-transactions/my-payments/:mandiId",
  paymentStatus: "/paymentStatus",

  catalogue: "/catalogue/:id",
  lotListing: "/lot-listing",
  wishlist: "/wishlist/:id",
  wishlistPhotoViewer: "/wishlist/photoviewer",

  catalogueReadOnly: "/catalogue/public/:mandiName",
  lotListingReadOnly: "/lot-listing/public/:mandiName",

  chatbot: "/chatbot/:purchaseDate/:ticketType",
  chatbotFromHelpPage: "/chatbotHelpPage/:ticketType",
  chatbotPreviousSession: "/chatbotWithDate/:purchaseDate/:ticketType/:cId",
  chatbotPreviousSessionWithoutDate: "/chatbotWithoutDate/:ticketType/:cId",
  helpAndSupport: "/help-and-support",
  serviceAgreement: "service/service-agreement",
  serviceSchedule: "service/schedule",
  termsAndConditions: "/terms-and-conditions",
};

export const settings = {
  elapseWindow: 30,
  latencyCompensation: 0.2, // 200ms buffer. Show lesser time left for the bidder than the actual time left.
  delayTime: 10,
  autoRedirectTime: 2,
  showLotTime: 2,
  tvScreenImageDuration: 2,
  myBuysDiffDays: 15,
  debouncingTime: 0.4,
  otpResendDuration: 1,
  animatedCrateInfoDuration: 4.5,
  bidderAccessRedirection: 3,
  checkForNewLotsDuration: 1200,
};

export const COUNTRY_CODE = {
  INDIA: "+91",
};

export const generalInfo = {
  mandiId: process.env.REACT_APP_MANDI_ID,
  helpLineNumber: `${COUNTRY_CODE.INDIA}8061931493`,
  contactToBuyLot: `${COUNTRY_CODE.INDIA}8978384888`,
};

export const MANDI_URL = process.env.REACT_APP_MANDI_URL;
export const VELYNK_URL = process.env.REACT_APP_VELYNK_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const networkStrengthParams = {
  numOfPingsToAvg: 5, // use last N pings to compute avg latency
  pingIntervel: 2000, // fire ping every N seconds
  numDocsPerSession: 5, // to persist N pings per session in firebase.
};

export const BIDDER_ACCESS = {
  COMPLETE_RESTRICTION: 0,
  ENTER_AUCTION_RESTRICTION: 2,
};

export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const PAGE_SRC = {
  AUCTION: "auction",
  CATALOGUE: "catalogue",
};
