import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, useTheme } from "@mui/material";
import { useLocale } from "locale/use-locale";

import styles from "./styles";

const InitialMessageOptions = (props) => {
  const theme = useTheme();
  const [clickedOptions, setClickedOptions] = useState("");
  const { id } = useParams();
  const { formatMessage } = useLocale();
  const options = [
    {
      text: formatMessage("billing"),
      handler: (text) => {
        handleOptionClick(text);
      },
      key: 1,
    },
    {
      text: formatMessage("payment"),
      handler: (text) => {
        handleOptionClick(text);
      },
      key: 2,
    },
    {
      text: formatMessage("quality"),
      handler: (text) => {
        handleOptionClick(text);
      },
      key: 3,
    },
    {
      text: formatMessage("others"),
      handler: (text) => {
        handleOptionClick(text);
      },
      key: 4,
    },
  ];

  const handleOptionClick = (text) => {
    if (!clickedOptions) {
      props.actions.handleOptions(text, "step1");

      setClickedOptions(text);
    }
  };

  const buttonMarkup = options.map((option) => (
    <button
      style={{
        ...styles.button,
        color: clickedOptions
          ? theme.palette.disabledButtonText
          : styles.button.color,
        backgroundColor: "white",
        cursor: clickedOptions ? "not-allowed" : "pointer",
      }}
      key={option.key}
      onClick={() => option.handler(option.text)}
      type="button"
    >
      {option.text}
    </button>
  ));

  return (
    <div style={styles.container}>
      <Grid container style={styles.gridContainer}>
        <Grid style={styles.description}>
          {formatMessage("please_choose_the_option", { id })}
        </Grid>
        <Grid id="initalMsg" style={styles.gridOptionsContainer}>
          {buttonMarkup}
        </Grid>
      </Grid>
    </div>
  );
};

export default InitialMessageOptions;
