import styled from "@emotion/styled";

export const ImageListWrapper = styled.div`
  display: inline-block;
  padding-bottom: ${({ theme }) => theme.spacing(0.5)};
  overflow-x: auto;
  background: #eeeeee;

  span {
    position: relative;
    display: flex;
    margin-right: ${({ theme }) => theme.spacing(1)};
    padding: 5px;
    background: #eeeeee;
    border-radius: 4px;

    img {
      width: 100px;
    }

    .cancel-icon {
      position: absolute;
      top: 1px;
      right: -2px;
    }

    .pdf-icon {
      font-size: ${({ theme }) => theme.typography.h2.fontSize};
    }
  }
`;

export const ImageWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

export const WebCamWrapper = styled.div`
  position: relative;
  width: 340px;
  height: 340px;
  background: #eeeeee;
  padding: 20px;

  div {
    position: relative;
    display: inline-block;
    .btn-danger {
      position: absolute;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      top: ${({ fullScreen = false }) => (fullScreen ? "81%" : "79%")};
      left: ${({ fullScreen = false }) => (fullScreen ? "50%" : "40%")};
      transform: ${({ fullScreen = false }) =>
        fullScreen ? "translateX(-50%) translateY(-25%)" : ""};
      background: #a33f3f;
      border: 1px solid black;
      opacity: 1;
      cursor: pointer;
    }
    .btn {
      display: flex;
      background-color: #e0e0e0 !important;
      padding: 0.5rem;
      border-radius: 50% !important;
      color: rgba(0, 0, 0, 0.87) !important;
      border: 0;
    }
    .btn-primary {
      background: transparent;
      position: absolute;
      top: 80%;
      left: 10%;
      border: 0;
      color: white;

      svg {
        cursor: pointer;
      }
    }
    .flash-btn {
      position: absolute;
      top: 10%;
    }
    .cancel-btn {
      position: absolute;
      top: 80%;
      left: 80%;
    }
    .icon-size {
      font-size: 2rem;
    }
  }
`;
