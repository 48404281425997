import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { translate } from "locale/formatter";
import { currency } from "utils/currencyFormatter";
import { fixedToXDecialPlaces } from "utils/mathExpert";

import Text from "components/Text";
import { TEXT_TYPES } from "components/Text/const";

const BidderInfoCard = ({ data, details }) => {
  const { colors } = useTheme();
  const {
    net_weight = 0,
    lots_bought = 0,
    truck_count = 0,
    total_value = 0,
  } = data;
  return (
    <Box
      className="flex justify-around py-3  mt-2"
      style={{ background: colors.lightGreen1 }}
    >
      <>
        <Box className="flex flex-col align-items-center">
          <Text>{lots_bought}</Text>
          <Text textType={TEXT_TYPES.SECONDARY}>{translate("units")}</Text>
        </Box>
        <Box className="flex flex-col align-items-center">
          <Text>{truck_count}</Text>
          <Text textType={TEXT_TYPES.SECONDARY}>{translate("truck")}</Text>
        </Box>
        <Box className="flex flex-col align-items-center">
          <Text>{fixedToXDecialPlaces(net_weight)}</Text>
          <Text textType={TEXT_TYPES.SECONDARY}>{translate("Kgs")}</Text>
        </Box>
        <Box className="flex flex-col align-items-center">
          <Text>
            {currency((details?.net_amount || total_value) / net_weight)}
          </Text>
          <Text textType={TEXT_TYPES.SECONDARY}>{translate("avgPriceKg")}</Text>
        </Box>
      </>
    </Box>
  );
};

export default BidderInfoCard;
