import React from "react";
import { Grid } from "@mui/material";
import { formatMessage } from "locale/components/locale-provider";

import CommonChatOptions from "./commonChatOptions";
import styles from "./styles";

const SecondMessageOptions = (props) => {
  return (
    <div style={styles.container}>
      <Grid container style={styles.gridContainer}>
        <Grid style={styles.description}>{formatMessage("tell_us_more")}</Grid>
        <CommonChatOptions isSkipNeeded {...props} />
      </Grid>
    </div>
  );
};

export default SecondMessageOptions;
