import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import app from "slices/app.slice";
import auction from "slices/auction.slice";
import catalogue from "slices/catalogue.slice";
import chatbot from "slices/chatbot.slice";

const store = configureStore({
  reducer: {
    app,
    auction,
    catalogue,
    chatbot,
  },
  middleware: [...getDefaultMiddleware({ serializableCheck: false })],
});

export default store;
