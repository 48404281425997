/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ArrowBackIos, ArrowForwardIos, Close } from "@mui/icons-material";
import { Box, IconButton, Modal } from "@mui/material";
import QualityIssues from "services/qualityIssues";
import { actions as chatbotActions } from "slices/chatbot.slice";

import styles, { classes } from "./styled";

const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
  state,
}) => {
  const dispatch = useDispatch();
  const { me } = useSelector((state) => state.app);
  const { chatSessionId } = useSelector((state) => state.chatbot);
  const [showImages, setShowImages] = useState(false);
  const [imageFiles, setImageFiles] = useState();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { purchaseDate } = useParams();

  const date = purchaseDate ? new Date(+purchaseDate).toLocaleDateString() : "";

  const {
    modalBox,
    closeIconBox,
    closeIcon,
    arrowForward,
    arrowForwardBox,
    arrowBack,
    arrowBackBox,
  } = classes();

  const imageUrlsArray = Object.values(state.imageUrls || {});

  const stateMessages = state?.messages || [];

  const attachments = stateMessages
    .map((item) => item.payload?.attachments)
    .filter((attachment) => attachment !== undefined && attachment.length > 0)
    .flat();

  const comments = stateMessages
    .map((item) => item.payload?.comments)
    .filter((attachment) => attachment !== undefined && attachment.length > 0);

  const payload = { messages: stateMessages };

  const heading = stateMessages
    .map((item) => item.payload?.heading)
    .filter((attachment) => attachment !== undefined && attachment.length > 0)
    .join(", ");

  const handleOptions = (text, step) => {
    const botMessageContent = text || "";
    if (step === "step1") {
      const botMessage = createChatBotMessage(botMessageContent, {
        widget: botMessageContent ? "SecondMessageStepOptions" : undefined,
        payload: botMessageContent
          ? {
              heading: text,
            }
          : undefined,
      });
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));

      // Ticket creation
      const finalPayload = {
        chatbot: {
          customer_id: me.customerId,
          payload,
          attachments,
          heading: text,
          comments: [...comments],
        },
      };

      finalPayload.chatbot.payload.messages.push(botMessage);
      QualityIssues.createChatbotTicket(finalPayload.chatbot, {
        customer_id: me.customerId,
        purchase_date: purchaseDate ? date : undefined,
        auction_user_id: me.velynk_id,
      })
        .then((res) => {
          dispatch(chatbotActions.setChatSessionId(res.id));
          setState((prev) => {
            return {
              ...prev,
              res,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    if (step === "step1_subsection") {
      const botMessage = createChatBotMessage(botMessageContent, {
        widget: botMessageContent ? "SecondMessageOptions" : undefined,
        payload: botMessageContent
          ? {
              comments: text,
            }
          : undefined,
      });
      setState((prev) => {
        return {
          ...prev,
          comments: [...comments, text],
          messages: [...prev.messages, botMessage],
        };
      });
      const finalPayload = {
        chatbot: {
          customer_id: me.customerId,
          payload,
          attachments,
          heading,
          comments: [...comments, text],
        },
      };

      finalPayload.chatbot.payload.messages.push(botMessage);
      QualityIssues.updateChatbotTicket(chatSessionId, finalPayload)
        .then((res) => {
          setState((prev) => {
            return {
              ...prev,
              res,
              messages: [...res.payload.messages],
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // This is for skip action
    if (step === "step2") {
      const botMessage = createChatBotMessage(botMessageContent, {
        widget: botMessageContent ? "recievedmesages" : undefined,
      });

      const finalPayload = {
        chatbot: {
          customer_id: me.customerId,
          payload,
          attachments,
          heading,
          comments: [...comments],
        },
      };

      finalPayload.chatbot.payload.messages.push(botMessage);
      QualityIssues.updateChatbotTicket(chatSessionId, finalPayload)
        .then((res) => {
          setState((prev) => {
            return {
              ...prev,
              res,
              messages: [...res.payload.messages],
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleShowImage = () => {
    setShowImages(true);
  };

  const handleImageSignedId = (signedIds, fileArray) => {
    setImageFiles(fileArray);
    setCurrentImageIndex(0);
  };

  const handleAnyMessage = (message) => {
    if (!message) return;

    const finalPayload = {
      chatbot: {
        customer_id: me.customerId,
        payload,
        attachments,
        heading,
        comments: [...comments, message],
      },
    };
    const isFirstComment =
      document.querySelectorAll("#ticketMessage").length === 0;

    const botMessage = createChatBotMessage(message, {
      widget: isFirstComment ? "recievedmesages" : "thanksmessages",
      payload: {
        comments: message,
      },
    });

    finalPayload.chatbot.payload.messages.push(botMessage);
    QualityIssues.updateChatbotTicket(chatSessionId, finalPayload)
      .then((res) => {
        setState((prev) => {
          return {
            ...prev,
            res,
            messages: [...res.payload.messages],
          };
        });
      })
      .catch((error) => {
        console.error(error);
      });

    document.querySelector(
      ".react-chatbot-kit-chat-input-container"
    ).style.display = "none";
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imageUrlsArray.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageUrlsArray.length - 1 : prevIndex - 1
    );
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleOptions,
            handleImageSignedId,
            handleAnyMessage,
            handleShowImage,
          },
        });
      })}

      <Modal
        sx={{ boxShadow: "none" }}
        open={showImages}
        onClose={() => setShowImages(false)}
      >
        <Box className={modalBox}>
          <Box className={closeIconBox}>
            <IconButton
              style={styles.imgCancelIcon}
              onClick={() => setShowImages(false)}
            >
              <Close className={closeIcon} />
            </IconButton>
          </Box>
          {imageUrlsArray && imageUrlsArray.length > 0 && (
            <div>
              {imageUrlsArray[currentImageIndex].content_type.includes(
                "audio"
              ) && (
                <audio controls key={imageUrlsArray[currentImageIndex]?.url}>
                  <source
                    src={imageUrlsArray[currentImageIndex]?.url}
                    type={imageUrlsArray[currentImageIndex]?.content_type}
                  />
                </audio>
              )}
              {imageUrlsArray[currentImageIndex].content_type.includes(
                "image"
              ) && (
                <img
                  src={imageUrlsArray[currentImageIndex]?.url}
                  key={imageUrlsArray[currentImageIndex]?.url}
                  alt={`img${currentImageIndex + 1}`}
                  width="100%"
                  height="100%"
                />
              )}
              {/* {imageUrlsArray[currentImageIndex].content_type.includes(
                "video"
              ) && (
                <video
                  width="320"
                  height="240"
                  controls
                  autoPlay
                  key={imageUrlsArray[currentImageIndex]?.url}
                >
                  <source
                    src={imageUrlsArray[currentImageIndex]?.url}
                    type="video/webm"
                  />
                </video>
              )} */}
            </div>
          )}
          {imageUrlsArray && imageUrlsArray.length > 1 && (
            <>
              <Box className={arrowBackBox}>
                <IconButton onClick={handlePreviousImage}>
                  <ArrowBackIos className={arrowBack} />
                </IconButton>
              </Box>
              <Box className={arrowForwardBox}>
                <IconButton onClick={handleNextImage}>
                  <ArrowForwardIos className={arrowForward} />
                </IconButton>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ActionProvider;
