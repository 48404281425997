import React from "react";
import { Grid } from "@mui/material";
import { formatMessage } from "locale/components/locale-provider";

import CommonChatOptions from "./commonChatOptions";
import styles from "./styles";

const ThanksMessage = (props) => {
  return (
    <div style={styles.container}>
      <Grid container style={styles.gridContainer}>
        <Grid style={styles.description}>
          {formatMessage("thank_you_for_feedback")}
        </Grid>
        <CommonChatOptions {...props} />
      </Grid>
    </div>
  );
};

export default ThanksMessage;
