import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import {
  AutorenewRounded as AutorenewRoundedIcon,
  CancelOutlined as CancelOutlinedIcon,
  Highlight as HighlightIcon,
} from "@mui/icons-material";
import StopCircleIcon from "@mui/icons-material/StopCircle";

import { WebCamWrapper } from "./styles";

const FACING_MODE_USER = "user";
const FACING_MODE_ENVIRONMENT = "environment";
const videoConstraints = {
  facingMode: FACING_MODE_ENVIRONMENT,
  audio: true,
};

const VIEW_TYPES = {
  FULL_SCREEN: "fullScreen",
};

const STYLES = {
  fullScreen: {
    parent: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "black",
      zIndex: "100",
    },
    cam: {
      position: "relative",
      top: "50%",
      transform: "translateY(-50%)",
      objectFit: "cover",
    },
  },
};

const LiveRecording = ({
  constraints = {},
  saveImages,
  Webstyle,
  upload,
  viewType,
  switchCamera,
  isFlash,
  isHalfCamView,
  uploadVideo,
}) => {
  const webcamRef = useRef(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [images, setImages] = useState([]);
  const [status, setStatus] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const didMount = useRef(false);

  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const recordedChunksRef = useRef([]);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    recordedChunksRef.current = []; // Clear previous recordings
    setRecordedChunks([]);

    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });

    mediaRecorderRef.current.addEventListener("dataavailable", ({ data }) => {
      if (data.size > 0) {
        recordedChunksRef.current.push(data);
        setRecordedChunks((prev) => prev.concat(data)); // For UI if needed
      }
    });

    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing]);

  const handleStopCaptureClick = useCallback(async () => {
    setCapturing(false);

    // Create a Promise to wait for the `stop` and `dataavailable` to complete
    await new Promise((resolve) => {
      mediaRecorderRef.current.addEventListener("stop", () => {
        resolve();
      });
      mediaRecorderRef.current.stop();
    });

    // Process the recorded chunks after `stop` has completed
    const currentChunks = recordedChunksRef.current;
    if (currentChunks.length) {
      const blob = new Blob(currentChunks, {
        type: "video/webm",
      });
      uploadVideo(blob, "video/webm");
    }
  }, [setCapturing]);

  const handleClick = useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER
    );
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
    } else {
      if (!("mediaDevices" in window.navigator)) {
        alert("Media Devices not available. Use HTTPS!");
      }

      const { mediaDevices } = window.navigator;

      mediaDevices.enumerateDevices().then((devices) => {
        const cameras = devices.filter(
          (device) => device.kind === "videoinput"
        );
        if (cameras?.length === 0) {
          alert(
            "No camera found. If your device has camera available, check permissions."
          );
          return;
        }

        const camera = cameras[cameras?.length - 1];
        mediaDevices
          .getUserMedia({
            video: {
              deviceId: camera?.deviceId,
            },
          })
          .then((stream) => {
            const track = stream.getVideoTracks()[0];
            if (!track.getCapabilities().torch) {
              alert("Torch not available");
            } else {
              track.applyConstraints({
                advanced: [
                  {
                    torch: status,
                  },
                ],
              });
            }
          });
      });
    }
  }, [status]);

  const parent = STYLES[viewType]?.parent || {};

  const camStyle = isHalfCamView ? { ...parent, height: "50%" } : parent;

  return (
    <>
      {upload && (
        <WebCamWrapper fullScreen={viewType === VIEW_TYPES.FULL_SCREEN}>
          <CancelOutlinedIcon
            className="cancel-icon icon-size"
            color="error"
            cursor="pointer"
            style={{ position: "absolute", top: "0", right: "0" }}
            onClick={() => {
              setStatus(false);
            }}
          />
          <div style={camStyle}>
            <Webcam
              audio
              ref={webcamRef}
              height={Webstyle?.height}
              width={Webstyle?.width}
              videoConstraints={{
                ...videoConstraints,
                ...constraints,
                facingMode,
              }}
              style={STYLES[viewType]?.cam || {}}
              switchCamera
            />

            {capturing ? (
              <StopCircleIcon
                onClick={handleStopCaptureClick}
                sx={{
                  marginLeft: "1rem",
                  height: "3rem",
                  width: "3rem",
                  color: "red",
                }}
                type="button"
                className="btn-danger"
              />
            ) : (
              <button
                onClick={() => {
                  handleStartCaptureClick();
                }}
                type="button"
                className="btn-danger"
              />
            )}

            {switchCamera && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClick}
              >
                <AutorenewRoundedIcon />
              </button>
            )}
            {isFlash && (
              <button
                type="button"
                className="btn btn-primary flash-btn icon-size"
                onClick={() => setStatus(!status)}
              >
                <HighlightIcon />
              </button>
            )}

            {viewType === VIEW_TYPES.FULL_SCREEN && (
              <button
                type="button"
                className="btn btn-primary cancel-btn"
                onClick={() => {
                  setStatus(false);
                }}
              >
                <CancelOutlinedIcon className="icon-size" />
              </button>
            )}
          </div>
        </WebCamWrapper>
      )}
    </>
  );
};
export default LiveRecording;
