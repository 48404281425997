import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import PropTypes from "prop-types";

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 4,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: "absolute",
  top: 2,
  right: "50%",
  ...theme.applyStyles("dark", {
    backgroundColor: grey[900],
  }),
}));

const CustomDrawer = ({ children, open, setOpen, onCloseHandler }) => {
  return (
    <>
      <SwipeableDrawer
        open={open}
        anchor="bottom"
        onClose={onCloseHandler}
        onOpen={() => setOpen(true)}
        PaperProps={{
          sx: {
            borderTopRightRadius: 12,
            borderTopLeftRadius: 12,
          },
        }}
      >
        <Puller />

        {children}
      </SwipeableDrawer>
    </>
  );
};

CustomDrawer.defaultProps = {
  open: false,
  setOpen: () => {},
};

CustomDrawer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default CustomDrawer;
