import { useMemo } from "react";
import { useIntl } from "react-intl";

export function useLocale() {
  const intl = useIntl();

  return useMemo(
    () => ({
      formatMessage: (id, params) => {
        return intl.formatMessage({ id }, params);
      },
    }),
    [intl]
  );
}
