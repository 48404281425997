/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";

import { ImagePreview } from "components";

import MicrophoneLoader from "../../../../../assets/gifs/microphoneLoader.gif";

import styles from "./styles";

const MicrophoneLoaderMessage = () => {
  return (
    <button style={styles.myCustomButton} type="button">
      <div style={styles.myCustomImgContainer}>
        <ImagePreview width="30" height="30" url={MicrophoneLoader} />
      </div>
    </button>
  );
};

export default MicrophoneLoaderMessage;
