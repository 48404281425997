import { CircularProgress } from "@mui/material";

const AppLoader = () => {
  return (
    <div className="AppLoader">
      <CircularProgress />
    </div>
  );
};

export default AppLoader;
