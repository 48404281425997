import React from "react";
import { Grid, Typography } from "@mui/material";
import { translate } from "locale/formatter";

import AppIcon from "../../../../../assets/images/appIcon.png";

import styles from "./styles";

const CustomMessage = (props) => {
  const timestamp = props.state.messages[0]?.timestamp;

  const formatTime = (date) => {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    const timeString = new Date(date).toLocaleTimeString("en-GB", options);
    return timeString;
  };

  const time = timestamp ? formatTime(timestamp) : formatTime(new Date());

  return (
    <div style={styles.customMessageContainer}>
      <Grid style={styles.customMessageImgContainer}>
        <img src={AppIcon} alt="appIcon" width="14px" height="14px" />
      </Grid>
      <Grid item style={styles.customMessageTextContainer}>
        <Typography style={styles.customMessageText}>
          {translate("fruitx_chat")} {time.toUpperCase()}
        </Typography>
      </Grid>
    </div>
  );
};

export default CustomMessage;
