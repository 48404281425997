/* eslint-disable jsx-a11y/media-has-caption */

import CancelIcon from "@mui/icons-material/Cancel";
import clsx from "clsx";
import {
  classes,
  ImgListContainer,
} from "pages/bidder/components/OrderReview/styled";

import { getImageLocalPath } from "./utils";

const ImagePreview = ({
  file,
  url,
  cancelStyle,
  removeAttachment,
  containerClassName,
  openUrl = false,
  ...rest
}) => {
  const { container } = classes();

  const fileSrc = file?.type ? getImageLocalPath(file) : url;

  const openUrlToPreview = () => {
    if (openUrl) {
      window.open(fileSrc, "_blank");
    }
  };

  const deleteAttachment = () => {
    removeAttachment();
  };

  return (
    <>
      <ImgListContainer className={clsx(container, containerClassName)}>
        <img src={fileSrc} alt={fileSrc} onClick={openUrlToPreview} {...rest} />

        {removeAttachment && (
          <CancelIcon
            className="cancel-icon"
            color="error"
            cursor="pointer"
            style={cancelStyle}
            onClick={deleteAttachment}
          />
        )}
      </ImgListContainer>
    </>
  );
};

export default ImagePreview;
