import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "atomic-components/Loader";
import useCutomerDetails from "hooks/useCustomerDetails";
import useMoEngage from "hooks/useMoEngage";
import { MY_BOOKS_EVENTS } from "utils/events";

import EmptyScreen from "components/EmptyScreen";

import OutstandingCard from "../OutstandingCard";

import Header from "./Header";
import { classes } from "./styled";
import TransactionCard from "./TransactionCard";

const MyTransactions = () => {
  const [loading, setLoading] = useState(false);
  const { me } = useSelector((state) => state.app);
  const [visibleCards, setVisibleCards] = useState([]);
  const tracker = useMoEngage();
  const [trackedCards, setTrackedCards] = useState([]);
  const { myBooksBg, scrollContainer } = classes();
  const [customerDetails] = useCutomerDetails(me, setLoading, setVisibleCards);

  const trackVisibleCards = (cards) => {
    const cardIds = cards
      .map((card) => card.customer_mandi_id)
      .filter((id) => !trackedCards.includes(id));

    cardIds.forEach((id) => {
      tracker(MY_BOOKS_EVENTS.MY_BOOKS_VERTICAL_SCROLL, {
        mandiId: `${id}`,
      });
    });

    setTrackedCards((prevTrackedCards) => [...prevTrackedCards, ...cardIds]);
  };

  const getElelement = () => {
    const cardIds = customerDetails.filter((card) => {
      const el = document.getElementById(`card-${card.customer_mandi_id}`);

      if (el) {
        const rect = el.getBoundingClientRect();
        return rect.top >= 0 && rect.top <= window.innerHeight;
      }
      return false;
    });

    setVisibleCards(cardIds);
  };

  useEffect(() => {
    const handleScroll = () => {
      getElelement();
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [customerDetails]);

  useEffect(() => {
    trackVisibleCards(visibleCards);
  }, [visibleCards]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={myBooksBg}>
      <Header title="my_books" />
      <div className="py-[1.5rem] px-[1rem]">
        <OutstandingCard customerDetails={customerDetails} />
      </div>
      <div className={scrollContainer}>
        {customerDetails?.length ? (
          <>
            {customerDetails.map((item, index) => (
              <TransactionCard CardItem={item} key={index} />
            ))}
            <div className="pb-[5rem]" />
          </>
        ) : (
          <div className="p-[5rem]">
            <EmptyScreen />
          </div>
        )}
      </div>
    </div>
  );
};

export default MyTransactions;
