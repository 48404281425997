/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import Chatbot from "react-chatbot-kit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { ArrowBackIosNew } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useLocale } from "locale/use-locale";
import QualityIssues from "services/qualityIssues";
import { actions as chatbotActions } from "slices/chatbot.slice";
import { getUserData } from "utils/localStorage";

import AppLoader from "components/AppLoader";

import ActionProvider from "./ActionProvider";
import config from "./config";
import MessageParser from "./MessageParser";

import "react-chatbot-kit/build/main.css";
import "./component/CustomChatStyles.scss";

const ChatBot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useLocale();
  const { id: customerId } = getUserData() || {};
  const { purchaseDate, ticketType, cId } = useParams();

  const { me } = useSelector((state) => state.app);

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);

  const date = purchaseDate ? new Date(+purchaseDate).toLocaleDateString() : "";

  const handleGoBack = () => navigate(-1);

  // TODO check this function use
  const saveMessages = () => {};

  const fetchMessages = async () => {
    try {
      let res = {};
      if (cId) {
        res = await QualityIssues.getChatbotMessageByChatSessionID(cId);
      } else {
        res = await QualityIssues.getChatbotMessage({
          customer_id: me.customerId,
          purchase_date: date,
          ticketType,
        });
      }
      dispatch(chatbotActions.setChatSessionId(res.id));
      const fetchedMessages = res.payload?.messages || [];
      const fetchedMessageText = res?.message || "";
      const fetchedImageUrls = res?.attachment_urls || {};
      if (res.status === 1) {
        fetchedMessages.push({
          id: null,
          message: "",
          type: "ticketClose",
          widget: "ticketClose",
        });
      }

      setMessages(fetchedMessages);
      config.state.messageText = fetchedMessageText;
      config.state.imageUrls = fetchedImageUrls;
      config.state.res = res;
    } catch (error) {
      console.debug("Error fetching chatbot messages:", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [cId]);

  useEffect(() => {
    return () => {
      config.state = {};
      setMessages([]);
      window.location.reload();
    };
  }, []);

  return (
    <div>
      <Grid
        container
        boxShadow={2}
        className="chatbot-header-container"
        alignItems="center"
        sx={{
          marginTop: Capacitor.getPlatform() === "ios" ? "50px" : "",
          height: "5rem",
        }}
      >
        <Grid container item className="chatbot-header" alignItems="center">
          <IconButton
            size="small"
            sx={{
              bgcolor: "common.black",
              "&:hover": { bgcolor: "common.black" },
              width: "2rem",
              height: "2rem",
            }}
            onClick={handleGoBack}
          >
            <ArrowBackIosNew
              fontSize="inherit"
              sx={{ color: "common.white" }}
            />
          </IconButton>
          <Grid item style={{ marginLeft: "1.25rem" }}>
            <Typography
              align="center"
              style={{
                fontSize: "1.6rem",
                lineHeight: "30px",
                color: "#667085",
              }}
            >
              {formatMessage("chat_with_us")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <AppLoader />
        </div>
      ) : (
        <>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            messageHistory={messages.length > 0 ? messages : null}
            placeholderText={formatMessage("please_add_your_comments")}
            saveMessages={saveMessages}
            key={messages.length}
            disableScrollToBottom
          />
        </>
      )}
    </div>
  );
};

export default ChatBot;
