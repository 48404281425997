import { velynkService } from "./base";

class QualityIssues {
  createChatbotTicket = (data, params) => {
    return velynkService.post(`chat_sessions`, data, { params });
  };

  updateChatbotTicket = (id, data) => {
    return velynkService.put(`chat_sessions/${id}`, data);
  };

  getChatbotMessageByChatSessionID = (chatSessionId) => {
    return velynkService.get(`chat_sessions/${chatSessionId}`, {
      params: {
        chat_session_id: chatSessionId,
      },
    });
  };

  getChatbotMessage = (params) => {
    return velynkService.get(`chat_sessions/show_by_purchase_date`, {
      params,
    });
  };

  getSupportTickets = (params) => {
    return velynkService.get("chat_sessions", {
      params,
    });
  };
}

export default new QualityIssues();
