import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatSessionId: null,
};

const slice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    setChatSessionId: (state, { payload }) => ({
      ...state,
      chatSessionId: payload,
    }),
  },
});

export const { actions } = slice;

export default slice.reducer;
