import { Rating } from "@mui/material";
import PropTypes from "prop-types";

const CustomRating = ({ rating, onChange, size, sx, ...props }) => {
  const handleChange = (event, newValue) => {
    onChange(newValue || +event.target.value);
  };

  return (
    <Rating
      name="simple-controlled"
      value={rating}
      onChange={handleChange}
      sx={sx}
      size={size}
      {...props}
    />
  );
};

CustomRating.defaultProps = {
  rating: 0,
  onChange: () => {},
  size: "small",
  sx: {},
};

CustomRating.propTypes = {
  rating: PropTypes.number,
  onChange: PropTypes.func,
  size: PropTypes.string,
  sx: PropTypes.object,
};

export default CustomRating;
