import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "slices/app.slice";
import { auth, firestore } from "utils/firebaseUtils/firebase";

const useUserLogout = (me) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!me?.id) return;

    const unsubscribe = firestore
      .collection("users")
      .doc(me?.id)
      .onSnapshot(
        async (doc) => {
          if (doc.exists) {
            const userData = doc.data();
            if (userData?.should_logout) {
              try {
                dispatch(actions.logout());
                await auth.signOut();
                await firestore
                  .collection("users")
                  .doc(me?.id)
                  .update({ should_logout: false });
              } catch (error) {
                console.error(
                  "Error during logout or Firestore update: ",
                  error
                );
              }
            }
          }
        },
        (error) => {
          console.error("Error fetching Firestore document: ", error);
        }
      );

    return () => unsubscribe();
  }, [me?.id, dispatch]);
};

export default useUserLogout;
