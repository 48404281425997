import { useSelector } from "react-redux";
import firebase from "firebase/app";

import "firebase/analytics";

let analytics;

const useTracker = () => {
  const { me } = useSelector((state) => state.app);

  const tracker = (event, params) => {
    if (process.env.NODE_ENV !== "production") {
      console.log(event, params);
    }

    if (me?.email && me?.email.endsWith("@vegrow.in")) {
      return;
    }

    if (!analytics) {
      analytics = firebase.analytics();
    }

    analytics.logEvent(event, {
      ...params,
      customer_id: me?.customerId || "",
      customer_name: me?.fullName || "",
    });
  };

  return tracker;
};

export default useTracker;
