import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { formatMessage } from "locale/components/locale-provider";

import styles from "./styles";

const TicketClose = () => {
  const ticketCloseID = document.querySelectorAll("#ticketCloseID");

  const secondMsgOptions = document.querySelectorAll("#secondMsgOptions");

  useEffect(() => {
    secondMsgOptions.forEach((msg) => (msg.style.display = "none"));
  }, [secondMsgOptions]);

  useEffect(() => {
    const shareComments = document.querySelectorAll("#comments");
    const shareIcons = document.querySelectorAll("#share");
    const VoiceNote = document.querySelectorAll("#voice_note");
    const VideoNote = document.querySelectorAll("#video_option");
    const skipBtn = document.querySelectorAll("#skip");
    if (ticketCloseID.length >= 1) {
      shareComments.forEach((btn) => (btn.style.display = "none"));
      VoiceNote.forEach((btn) => (btn.style.display = "none"));
      VideoNote.forEach((btn) => (btn.style.display = "none"));
      shareIcons.forEach((btn) => (btn.style.display = "none"));
      skipBtn.forEach((msg) => (msg.style.display = "none"));
    }
  }, [ticketCloseID]);

  return (
    <div style={styles.container} id="ticketCloseID">
      <Grid container style={styles.gridContainer}>
        <Grid style={styles.description}>
          {formatMessage("report_a_new_issue_description")}
        </Grid>
      </Grid>
    </div>
  );
};

export default TicketClose;
