import { Box, Button, Grid, styled, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const FlexContainer = styled(Grid)(() => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
});

export const StyledButton = styled(Button)(({ theme }) => {
  return {
    textTransform: "capitalize",
    background: theme.palette.primary.main,
    borderRadius: "0.5rem",
    color: "white",
  };
});

export const SubHeaderText = styled(Typography)({
  fontSize: "1.125rem",
});

export const StyledContainer = styled(Box)(({ theme }) => {
  return {
    padding: "1rem",
    background: theme.palette.primary.contrastText,
    borderRadius: "1.125rem",
    border: "1px solid #EEEEEE",
  };
});

export const ReviewContainer = styled(Box)(() => ({
  boxShadow: "1px 0px 4px 0px #0000001A",
  border: "1px solid #87878740",
  borderRadius: "1rem",
  color: "#000000",
  fontWeight: 510,
  padding: "1rem",
  display: "flex",
  flexDirection: "column",
}));

export const RateText = styled(Typography)(() => ({
  fontSize: "1.2rem",
  color: "#000000",
  fontWeight: "510",
}));

export const ImgListContainer = styled("div")(() => ({
  display: "flex",
  gap: "1rem",
  overflowX: "auto",
  position: "relative",
}));

export const classes = makeStyles(() => ({
  noBorder: {
    border: "none",
    "& input::placeholder": {
      fontSize: "10px",
    },
  },
  font12: {
    fontSize: "1.125rem",
    padding: "0.85rem 0.8rem",
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "1.125rem",
      fontWeight: 510,
      color: "#878787",
    },
    "& input:focus": {
      boxShadow: "none",
    },
  },
  cancelIconStyle: {
    fontSize: "1.6rem",
    position: "absolute !important",
    top: "1px",
    right: "-2px",
  },
}));
