import React from "react";
import { Grid } from "@mui/material";
import { formatMessage } from "locale/components/locale-provider";

import CommonChatOptions from "./commonChatOptions";
import styles from "./styles";

const LastMessage = (props) => {
  const { res } = props.state;

  const freshdesk_ticket_id =
    res?.freshdesk_ticket_id ||
    res?.payload?.completed_freshdesk_ticket_ids?.[0];

  return (
    <div style={styles.container} id="ticketMessage">
      <Grid container style={styles.gridContainer}>
        <Grid style={styles.description}>
          {formatMessage("received_your_detail", { id: freshdesk_ticket_id })}
        </Grid>
        <CommonChatOptions {...props} />
      </Grid>
    </div>
  );
};

export default LastMessage;
