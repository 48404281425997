/* eslint-disable import/imports-first */
import React from "react";
import { createRoot } from "react-dom/client";
import App from "app";

import LocaleProvider from "./locale/components/locale-provider";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import "theme/reset.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const domNode = document.getElementById("root");
const root = createRoot(domNode);

root.render(
  <LocaleProvider>
    <App />
  </LocaleProvider>
);

if (process.env.NODE_ENV === "development" && "serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register(`/serviceworker.js`).then(
      (registration) => {
        console.log(
          "ServiceWorker registration successful with scope: ",
          registration.scope
        );
      },
      (err) => {
        console.log("ServiceWorker registration failed: ", err);
      }
    );
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
