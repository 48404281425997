/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from "react";
import { createCustomMessage } from "react-chatbot-kit";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { Grid, useTheme } from "@mui/material";
import { VoiceRecorder } from "capacitor-voice-recorder";
import { formatMessage } from "locale/components/locale-provider";
import QualityIssues from "services/qualityIssues";
import { actions as chatbotActions } from "slices/chatbot.slice";
import { base64ToFile } from "utils";
import { getUserData } from "utils/localStorage";
import { notifySnackbar } from "utils/notify";

import AppLoader from "components/AppLoader";
import fileUpload, { imageCompressUpload } from "components/ImagePreview/utils";
import WebCamCapture from "components/Webcam";

import config from "../config";

import styles from "./styles";

const CommonChatOptions = ({ isSkipNeeded = false, ...props }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { me } = useSelector((state) => state.app);
  const params = useParams();
  const { chatSessionId } = useSelector((state) => state.chatbot);
  const { purchaseDate } = params;

  const [upload, setUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSkipDisabled, setIsSkipDisabled] = useState(false);
  const [hasAudioStarted, setHasAudioStarted] = useState(false);
  const [startVideo, setStartVideo] = useState(false);
  const [propsMessages, setPropsMessages] = useState(props.state.messages);

  const { id: customer_id } = getUserData() || {};
  const date = purchaseDate ? new Date(+purchaseDate).toLocaleDateString() : "";

  useEffect(() => {
    setPropsMessages(props.state.messages);
  }, [props.state.messages]);

  const [options, setOptions] = useState([
    {
      text: formatMessage("share_images"),
      handler: () => {
        handleButtonClick();
      },
      id: "share",
    },
    {
      text: formatMessage("add_comments"),
      handler: () => {
        handleComment();
      },
      id: "comments",
    },
  ]);

  useEffect(() => {
    if (isSkipNeeded) {
      setOptions((prev) => [
        ...prev,
        {
          text: formatMessage("skip"),
          handler: (text) => {
            handleOptionClick(text);
          },
          id: "skip",
        },
      ]);
    }
  }, [isSkipNeeded]);

  // TODO check with skip button
  const shareComments = document.querySelectorAll("#comments");
  const shareIcons = document.querySelectorAll("#share");
  const VoiceNote = document.querySelectorAll("#voice_note");
  const initalMsg = document.querySelectorAll("#initalMsg");
  const skipBtn = document.querySelectorAll("#skip");
  const secondMsgOptions = document.querySelectorAll("#secondMsgOptions");
  const videoOptions = document.querySelectorAll("#video_option");

  useEffect(() => {
    shareComments.forEach((btn, idx) => {
      if (idx >= 1) skipBtn.forEach((msg) => (msg.style.display = "none"));
      btn.style.display = idx !== shareComments.length - 1 ? "none" : "block";
    });

    VoiceNote.forEach((btn, idx) => {
      btn.style.display = idx !== shareComments.length - 1 ? "none" : "block";
    });

    videoOptions.forEach((btn, idx) => {
      btn.style.display = idx !== shareComments.length - 1 ? "none" : "block";
    });

    shareIcons.forEach((btn, idx) => {
      btn.style.display = idx !== shareIcons.length - 1 ? "none" : "block";
    });

    initalMsg.forEach((msg) => (msg.style.display = "none"));
    secondMsgOptions.forEach((msg) => (msg.style.display = "none"));
  }, [shareComments]);

  const attachments = propsMessages
    .map((item) => item.payload?.attachments)
    .filter((attachment) => attachment !== undefined && attachment.length > 0)
    .flat();

  const comments = propsMessages
    .map((item) => item.payload?.comments)
    .filter((attachment) => attachment !== undefined && attachment.length > 0);

  // If there many more scenario like this. Make all types in an array and just check it with includes.
  const payload = {
    messages: propsMessages.filter((item) => item.type !== "microphoneLoader"),
  };

  const heading = propsMessages
    .map((item) => item.payload?.heading)
    .filter((attachment) => attachment !== undefined && attachment.length > 0)
    .join(", ");

  const handleOptionClick = (text) => {
    setIsSkipDisabled(true);
    props.actions.handleOptions(text, "step2");
  };

  const handleComment = () => {
    setIsSkipDisabled(true);
    document.querySelector(
      ".react-chatbot-kit-chat-input-container"
    ).style.display = "block";
  };

  const updateConfig = (res) => {
    const fetchedMessageText = res?.message || "";
    const fetchedImageUrls = res?.attachment_urls || [];
    config.state.messageText = fetchedMessageText;
    config.state.imageUrls = fetchedImageUrls;
    config.state.res = res;

    props.setState((prev) => {
      return {
        ...prev,
        res,
        imageUrls: fetchedImageUrls,
        messages: [...res.payload.messages],
      };
    });
  };

  const handleUpdateCall = async (signedIds, fileArray) => {
    const finalPayload = {
      chatbot: {
        customer_id,
        payload,
        attachments: [...attachments, ...signedIds],
        heading,
        comments: comments || [],
      },
    };

    const isFirstAttachment =
      document.querySelectorAll("#ticketMessage").length === 0;

    const customImageMessage = createCustomMessage("", "customImage", {
      widget: isFirstAttachment ? "recievedmesages" : "thanksmessages",
      payload: {
        attachments: signedIds,
        imageFiles: fileArray,
      },
    });

    finalPayload.chatbot.payload.messages.push(customImageMessage);
    await QualityIssues.updateChatbotTicket(chatSessionId, finalPayload)
      .then((res) => {
        updateConfig(res);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleButtonClick = (text) => {
    setIsSkipDisabled(true);
    handleUpload(true);
    if (fileInputRef.current) {
      fileInputRef.current.click(text);
    }
  };

  const handleImage = async (files) => {
    setLoading(true);
    const fileArray = Array.from(files);
    try {
      const results = await Promise.all(
        fileArray.map(async (file) => {
          const { data } = await imageCompressUpload(file);
          handleUpload(!upload);
          return data?.id;
        })
      );
      const signedIds = results.filter(Boolean);
      await handleUpdateCall(signedIds, fileArray, "image");
      await props.actions.handleImageSignedId(signedIds, fileArray);
      setLoading(false);
    } catch (error) {
      notifySnackbar("Error uploading images:", "error");
    }
  };

  const stopRecording = async () => {
    await props.setState((prev) => ({
      ...prev,
      messages: [...prev.messages.slice(0, -1)],
    }));
    await VoiceRecorder.stopRecording()
      .then(async (result) => {
        setHasAudioStarted(false);
        const base64Sound = result.value.recordDataBase64;
        const { mimeType } = result.value;
        const fileName = `audio.webm`;
        const file = base64ToFile(base64Sound, fileName, mimeType);

        const blobIds = await fileUpload([file]);
        await handleUpdateCall(blobIds, file, "audio");
      })
      .catch(() => {
        setHasAudioStarted(false);
        notifySnackbar(formatMessage("error_stopping_the_recording"), "error");
      });
  };

  const uploadVideo = async (blob, mimeType) => {
    const fileName = "recorded-video.webm";
    const file = new File([blob], fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });

    const blobIds = await fileUpload([file]);
    await handleUpdateCall(blobIds, file, "video");
    setStartVideo(false);
  };

  const handleAudio = async () => {
    const customImageMessage = createCustomMessage("", "microphoneLoader");
    props.setState((prev) => ({
      ...prev,
      messages: [...prev.messages, customImageMessage],
    }));

    VoiceRecorder.canDeviceVoiceRecord()
      .then(() => {
        VoiceRecorder.requestAudioRecordingPermission().then(() => {
          VoiceRecorder.hasAudioRecordingPermission().then(() => {
            VoiceRecorder.startRecording()
              .then(() => {
                setHasAudioStarted(true);
              })
              .catch((error) => console.log(error));
          });
        });
      })
      .catch(() => {
        setHasAudioStarted(false);
        notifySnackbar(formatMessage("error_starting_the_recording"), "error");
      });
  };

  const buttonMarkup = options.map((option) => {
    return (
      <button
        id={option.id}
        style={{
          ...styles.button,
          color:
            option.text === "Skip" && isSkipDisabled
              ? theme.palette.disabledButtonText
              : styles.button.color,
          backgroundColor: "white",
          cursor:
            option.text === "Skip" && isSkipDisabled
              ? "not-allowed"
              : "pointer",
        }}
        key={option.id}
        onClick={() => option.handler(option.text)}
        type="button"
      >
        {option.text}
      </button>
    );
  });

  const handleUpload = () => setUpload(!upload);

  return (
    <Grid>
      <input
        type="file"
        name="Share images"
        accept="image/*, .heic"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(event) => {
          handleImage(event.target.files);
        }}
        capture="camera"
      />
      <div id="voice_note" style={{ display: "flex", gap: "1.5rem" }}>
        <button
          style={{
            ...styles.button,
            color: styles.button.color,
            backgroundColor: "white",
            cursor: "pointer",
          }}
          onClick={handleAudio}
          disabled={hasAudioStarted}
          type="button"
        >
          {formatMessage("start_audio")}
        </button>
        {hasAudioStarted && (
          <StopCircleIcon
            onClick={stopRecording}
            sx={{
              marginLeft: "1rem",
              height: "3rem",
              width: "3rem",
              color: "red",
            }}
          />
        )}
      </div>
      {/* <div id="video_option" style={{ display: "flex", gap: "1.5rem" }}>
        <button
          style={{
            ...styles.button,
            color: styles.button.color,
            backgroundColor: "white",
            cursor: "pointer",
          }}
          onClick={() => setStartVideo(true)}
          type="button"
        >
          {formatMessage("start_video")}
        </button>
      </div> */}
      {buttonMarkup}
      <WebCamCapture
        upload={startVideo}
        isHalfCamView={false}
        switchCamera
        viewType="fullScreen"
        isFlash
        videoConstraints={{
          facingMode: "user",
        }}
        uploadVideo={uploadVideo}
      />
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "1rem 0rem",
          }}
        >
          <AppLoader />
        </div>
      )}
    </Grid>
  );
};

export default CommonChatOptions;
