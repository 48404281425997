import { createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
import { useTheme } from "@mui/styles";

// import muiTheme from "../../config/theme";
import CustomMessage from "./component/CustomMessage";
import InitialMessageOptions from "./component/InitialMessageWidget";
import MicrophoneLoaderMessage from "./component/MicrophoneLoader";
import MyCustomChatMessage from "./component/MyCustomChatMessage";
import LastMessage from "./component/RecievedMessageWidget";
import SecondMessageOptions from "./component/SecondMessageWidget";
import SecondMessageStepOptions from "./component/SecondStepWidget";
import ThanksMessage from "./component/ThanksMessageWidget";
import TicketClose from "./component/TicketClose";

const config = {
  initialMessages: [
    createCustomMessage("", "custom", { timestamp: new Date() }),
    createChatBotMessage("", { widget: "InitialMessageOptions" }),
  ],
  customStyles: {
    botMessageBox: {
      // backgroundColor: muiTheme.palette.chatMessageBox,
      fontSize: "12px",
    },
    chatButton: {
      // backgroundColor: muiTheme.palette.secondary.contrastText,
      // border: `1px solid ${muiTheme.palette.border.lightGray}`,
    },
  },

  state: {
    messageText: "",
  },

  widgets: [
    {
      widgetName: "InitialMessageOptions",
      widgetFunc: (props) => <InitialMessageOptions {...props} />,
    },
    {
      widgetName: "SecondMessageStepOptions",
      widgetFunc: (props) => <SecondMessageStepOptions {...props} />,
    },
    {
      widgetName: "SecondMessageOptions",
      widgetFunc: (props) => <SecondMessageOptions {...props} />,
    },
    {
      widgetName: "recievedmesages",
      widgetFunc: (props) => <LastMessage {...props} />,
      mapStateToProps: ["messageText"],
    },
    {
      widgetName: "thanksmessages",
      widgetFunc: (props) => <ThanksMessage {...props} />,
      mapStateToProps: ["messageText"],
    },
  ],

  customMessages: {
    custom: (props) => <CustomMessage {...props} />,
    customImage: (props) => <MyCustomChatMessage {...props} />,
    microphoneLoader: (props) => <MicrophoneLoaderMessage {...props} />,
    ticketClose: (props) => <TicketClose {...props} />,
  },
};

export default config;
