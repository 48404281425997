/* eslint-disable prefer-promise-reject-errors */
import { DirectUpload } from "activestorage";
import imageCompression from "browser-image-compression";
import { getUserData } from "utils/localStorage";

export const getImageLocalPath = (file) => {
  return (window.URL || window.webkitURL).createObjectURL(file);
};

export const imageUpload = (
  file,
  props = false,
  field = false,
  previewField = false
) => {
  const user = getUserData();
  if (!file?.type) {
    return Promise.resolve();
  }
  return new Promise((resolve, reject) => {
    if (props) {
      props.change(previewField, "random");
    }

    const upload = new DirectUpload(
      file,
      `${process.env.REACT_APP_VELYNK_URL}/rails/active_storage/direct_uploads`,
      {
        directUploadWillCreateBlobWithXHR: (xhr) => {
          // Put my JWT token in the auth header here
          user?.token && xhr.setRequestHeader("Authorization", user.token);
          // Send progress upload updates
          // xhr.upload.addEventListener('progress', event => this.directUploadProgress(event));
        },
      }
    );

    return upload.create((error, blob) => {
      if (error) {
        reject(error);
      } else {
        if (props) {
          props.change(field, blob.signed_id);
          props.change(previewField, blob.service_url);
        }
        resolve({ data: { ...blob, link: blob.service_url } });
      }
    });
  });
};

const FILE_SIZE = 250000; //! 250kb

export function imageCompressUpload(file, compressRequired = true) {
  if (!file?.type) {
    return Promise.resolve();
  }
  return new Promise((resolve) => {
    if (
      compressRequired &&
      file.type !== "application/pdf" &&
      file.size > FILE_SIZE
    ) {
      const imgCompressOptions = {
        maxSizeMB: 0.25,
        useWebWorker: true,
        maxIteration: 30,
        maxWidthOrHeight: 1920,
      };
      imageCompression(file, imgCompressOptions).then((compressedFile) => {
        resolve(imageUpload(compressedFile));
      });
    } else {
      resolve(imageUpload(file));
    }
  });
}

const fileUpload = async (file) => {
  if (file && file.length > 0) {
    await Promise.all(file?.map((value) => imageUpload(value))).then((res) => {
      file = res.filter(Boolean)?.map(({ data }) => data?.id);
    });
  }
  return new Promise((resolve) => {
    resolve(file);
  });
};

export default fileUpload;
