import { makeStyles } from "@mui/styles";

export const classes = makeStyles(() => ({
  aceeptButton: {
    textTransform: "lowercase !important",
  },
  iframeStyle: {
    width: "100%",
    height: "100%",
    border: "none",
    position: "relative",
    top: "-1rem",
  },
  actionStyle: {
    display: "flex",
    flexDirection: "column",
    padding: "21px",
    position: "sticky",
    bottom: 0,
    background: "white",
    boxShadow: "0px -1px 73.9px 0px #00000040",
    borderTopLeftRadius: "14px",
    borderTopRightRadius: "14px",
  },
  buttonStyle: {
    color: "white",
    margin: "10px",
    width: "100%",
    textAlign: "center",
    borderRadius: "5px",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  formControl: {
    display: "flex",
    justifyContent: "start",
    alignItems: "flex-start",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #EEEEEE",
    background: `linear-gradient(180deg, #e6f7fc 0%, #ffffff 100%)`,
    position: "sticky",
    top: 0,
  },
  headerText: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
  },
}));

export const dialogStyle = {
  height: "70%",
  position: "relative",
};
