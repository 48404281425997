import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { formatMessage } from "locale/components/locale-provider";

import styles from "./styles";

const SecondMessageStepOptions = (props) => {
  const [options, setOptions] = useState([]);

  const initalMsg = document.querySelectorAll("#initalMsg");

  useEffect(() => {
    initalMsg.forEach((msg) => (msg.style.display = "none"));
  }, []);

  const BillingOptions = [
    "incorrect_weight",
    "incorrect_rate",
    "incorrect_grade/sku_mentioned",
    "incorrect_details",
    "others",
  ];

  const PaymentOptions = [
    "payment_not_mapped",
    "unable_to_make_payment",
    "wrong_outstanding",
    "waapsi_not_cleared",
    "others",
  ];

  const QualityOptions = ["grading_issue", "sorting_issue", "others"];
  const OtherOptions = ["NA"];

  useEffect(() => {
    switch (props.payload.heading) {
      case "Billing":
        setOptions(BillingOptions);
        break;

      case "Payment":
        setOptions(PaymentOptions);
        break;

      case "Quality":
        setOptions(QualityOptions);
        break;

      case "Others":
        setOptions(OtherOptions);
        break;

      default:
        setOptions(BillingOptions);
        break;
    }
  }, [props.payload.heading]);

  const buttonMarkup = options.map((option, idx) => (
    <button
      key={idx}
      type="button"
      style={{
        ...styles.button,
        color: styles.button.color,
        backgroundColor: "white",
        cursor: "pointer",
        width: "fit-content",
      }}
      onClick={() =>
        props.actions.handleOptions(formatMessage(option), "step1_subsection")
      }
    >
      {formatMessage(option)}
    </button>
  ));

  return (
    <div style={styles.container}>
      <Grid container style={styles.gridContainer}>
        <Grid style={styles.description}>{formatMessage("second_step_description")}</Grid>
        <Grid id="secondMsgOptions" style={styles.gridOptionsContainer}>
          {buttonMarkup}
        </Grid>
      </Grid>
    </div>
  );
};

export default SecondMessageStepOptions;
